import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { API } from "aws-amplify";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

class FinalizeOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedGame: props.selectedGame,
      game: null,
      selectedSeat: props.selectedSeat,
      qty: props.qty,
      seat: null,
      isLoading: true,
      name: null,
      email: null,
      mobileNo: null,
      paymentRefNo: null,
      succeeded: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  async componentDidMount() {
    const game = await this.game(this.props.selectedGame);
    const seat = await this.seat(
      this.props.selectedSeat,
      this.props.selectedGame
    );

    if (seat.isArchived) {
      alert("This seat is recently archived... Please select another...");
    }

    if (seat.isSold || seat.noOfSeats - seat.seatsSold === 0) {
      alert("This seat has been sold... Please select another...");
      this.props.history.push(
        `/orders/create/?step=select-seat&game=76ada460-97ef-11e9-907b-130afab5b563&qty=2`
      );
    }

    this.setState({
      seat,
      isLoading: false,
      game,
    });
  }

  game(id) {
    return API.get("v2", `marketplace/events/by/eventId/${id}`);
  }

  seat(seat, game) {
    return API.get(
      "v2",
      `marketplace/listings/by/eventId/${game}?seatId=${seat}&include_seller_details=true`
    );
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  async submitForm(e) {
    e.preventDefault();
    this.setState({
      isLoading: true,
    });

    const {
      name,
      email,
      mobileNo,
      paymentRefNo,
      notes,
      selectedGame,
      selectedSeat,
      qty,
      seat,
      game,
    } = this.state;

    const section = seat.zone === "Upper Zone" ? seat.zone : "Section";

    const gameDate = `${moment
      .tz(game.date, game.timezone)
      .format("ddd, MMM D YYYY")} at ${moment
      .tz(game.date, game.timezone)
      .format("h:mm A")}`;

    const description = `${this.state.qty} ticket(s): ${game.longName} - ${gameDate}, ${section} ${seat.zoneNo} Row ${seat.row}`;

    try {
      await API.post("v2", "marketplace/orders/manual-checkout", {
        body: {
          name,
          email,
          mobileNo,
          paymentRefNo,
          notes,
          description,
          noOfSeats: qty,
          game: selectedGame,
          seat: selectedSeat,
          ticketPrice: seat.price,
          amount: qty * seat.price,
        },
      });

      this.setState({
        isLoading: false,
        succeeded: true,
      });

      alert("Order created!");
    } catch (e) {
      if (e.response) {
        const { data } = e.response;

        if (!data.isSeatAvailable) {
          alert(data.message);
        }
      } else {
        alert("Something went wrong. Ask Jan about the error.");
      }
    }
  }

  renderGameDetails() {
    const { classes } = this.props;
    const { longName, date, timezone } = this.state.game;

    return (
      <div className={classes.gameDetails}>
        <Typography variant="body2">{longName}</Typography>
        <Typography variant="body1">
          {moment.tz(date, timezone).format("ddd, MMM DD YYYY")} at{" "}
          {moment.tz(date, timezone).format("h:mm A")}
        </Typography>
      </div>
    );
  }

  renderSeatDetails() {
    const { classes } = this.props;

    if (!this.state.seat) {
      return (
        <div className={classes.seatDetails}>
          <Typography variant="body2">
            Loading seat... Please wait...
          </Typography>
        </div>
      );
    }

    const { zone, zoneNo, row, price, sellerFullName, sellerEmail } =
      this.state.seat;

    return (
      <div className={classes.seatDetails}>
        <Typography variant="body2">
          {zone} {zoneNo} Row {row}
        </Typography>
        <Typography variant="body1">
          {sellerFullName} - {sellerEmail}
        </Typography>

        <Typography
          variant="body2"
          style={{ marginTop: "1rem", color: "#4caf50" }}
        >
          ${price.toFixed(2)} / seat
        </Typography>
        <Typography variant="body2">
          ${(price.toFixed(2) * this.state.qty).toFixed(2)} Total
        </Typography>
      </div>
    );
  }

  renderForm() {
    const { classes } = this.props;

    return (
      <form onSubmit={this.submitForm} className={classes.form}>
        <TextField
          disabled={this.state.isLoading}
          id="name"
          label="Name"
          className={classes.textField}
          value={this.state.name}
          onChange={this.handleChange("name")}
          margin="normal"
          fullWidth={true}
          required
        />

        <TextField
          disabled={this.state.isLoading}
          id="email"
          label="Email"
          className={classes.textField}
          value={this.state.email}
          onChange={this.handleChange("email")}
          margin="normal"
          fullWidth={true}
          required
        />

        <TextField
          disabled={this.state.isLoading}
          id="mobileNo"
          label="Mobile No."
          className={classes.textField}
          value={this.state.mobileNo}
          onChange={this.handleChange("mobileNo")}
          margin="normal"
          fullWidth={true}
          required
        />

        <TextField
          disabled={this.state.isLoading}
          id="paymentRefNo"
          label="Payment Ref#"
          className={classes.textField}
          value={this.state.paymentRefNo}
          onChange={this.handleChange("paymentRefNo")}
          margin="normal"
          fullWidth={true}
        />

        <TextField
          disabled={this.state.isLoading}
          id="notes"
          label="Notes"
          className={classes.textField}
          value={this.state.notes}
          multiline
          rowsMax="4"
          onChange={this.handleChange("notes")}
          margin="normal"
          fullWidth={true}
        />

        <div className={classes.buttons}>
          <Button
            disabled={this.isLoading}
            className={classes.button}
            color="default"
            variant="contained"
            href={`/orders/create/?step=select-seat&game=${this.state.selectedGame}&seat=${this.state.selectedSeat}&qty=${this.state.qty}`}
          >
            Back to Seats
          </Button>
          <Button
            className={classes.button}
            color="secondary"
            variant="contained"
            disabled={
              !this.state.selectedSeat ||
              this.state.isLoading ||
              this.state.succeeded
            }
            type="submit"
            onClick={this.submitForm}
          >
            Submit Order
          </Button>
        </div>
      </form>
    );
  }

  render() {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="display2" style={{ marginBottom: "2rem" }}>
            3. Finalize Order
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {this.state.isLoading ? (
            <Typography variant="body2">Loading...</Typography>
          ) : (
            <React.Fragment>
              {this.renderGameDetails()}
              {this.renderSeatDetails()}
            </React.Fragment>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          {this.renderForm()}
        </Grid>
      </Grid>
    );
  }
}

FinalizeOrder.propsType = {
  selectedGame: PropTypes.string.isRequired,
  selectedSeat: PropTypes.string.isRequired,
  qty: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};

export default FinalizeOrder;
