import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CanucksSectionSelect from "./CanucksSectionSelect";
import ReginaSectionSelect from "./ReginaSectionSelect";
import LionsSpecialSectionSelect from "./LionsSpecialSectionSelect";
import LionsSectionSelect from './LionsSectionSelect';

class SpecialSectionSelect extends Component {
  render() {
    const { selectedTeam, onSectionSelection, section } = this.props;
    if (selectedTeam === "toronto-raptors") {
      return (
        <CanucksSectionSelect
          onSectionSelection={onSectionSelection}
          section={section}
        />
      );
    } else if (selectedTeam === "vancouver-whitecaps-fc") {
      return (
        <LionsSectionSelect
          onSectionSelection={onSectionSelection}
          section={section}
        />
      );
    } else if (selectedTeam === "bc-lions") {
      return (
        <LionsSpecialSectionSelect
          onSectionSelection={onSectionSelection}
          section={section}
        />
      );
    } else {
      return (
        <ReginaSectionSelect
          onSectionSelection={onSectionSelection}
          section={section}
        />
      );
    }
  }
}

SpecialSectionSelect.propsType = {
  selectedTeam: PropTypes.string.isRequired,
  onSectionSelection: PropTypes.func.isRequired,
  section: PropTypes.string.isRequired
};

export default SpecialSectionSelect;
