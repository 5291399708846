import React, { Component } from "react";
import { API } from "aws-amplify";
import moment from "moment-timezone";
import _ from "lodash";

import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button, FormHelperText } from "@material-ui/core";
import TEAMS from "../constants/Teams";

const styles = {
  root: {
    flexGrow: 1,
  },
  teamsList: {
    marginBottom: "1.5rem",
  },
  teamItemWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  teamLogoWrapper: {
    marginBottom: "1.5rem",
    cursor: "pointer",
  },
  teamLogo: {
    height: 50,
    width: 50,
  },
  list: {
    marginTop: "1rem",
    maxHeight: "calc(100vh - 11rem)",
    overflow: "scroll",
  },
};

class SendGameSMS extends Component {
  constructor(props) {
    super(props);

    this.state = {
      games: null,
      isLoading: false,
      selectedGame: null,
      selectedTeam: null,
      msg: "",
      showDialog: false,
      isSubmitting: false,
      isBuyerSelected: true,
      isSellerSelected: false,
      recipients: [],
      isAllSelected: false,
    };

    this.handleGameChange = this.handleGameChange.bind(this);
    this.onTeamSelection = this.onTeamSelection.bind(this);
    this.sendSms = this.sendSms.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.selectAll = this.selectAll.bind(this);
  }

  async componentWillMount() { }

  async handleGameChange(e) {
    const selectedGame = e.target.value;

    this.setState({
      isLoading: true,
      recipients: [],
    });

    const orders = await this.getOrders(selectedGame);
    const seats = await this.getSeats(selectedGame);
    const sellers = this.getSellers(seats);
    const buyers = this.getBuyers(orders);

    this.setState({
      selectedGame,
      sellers,
      buyers,
      orders,
      isLoading: false,
    });
  }

  renderGames() {
    const { games, selectedGame, isLoading } = this.state;

    if (isLoading) {
      return (
        <Typography variant="title" align="center">
          Loading... Please wait...
        </Typography>
      );
    }

    if (!games) {
      return;
    }

    const menuItems = games.map((game) => {
      const { id, date, isTbd } = game;

      const regularGameDate = `${moment(date)
        .tz(game.timezone)
        .format("MMM DD h:mm A")}`;
      const gameDate = isTbd ? "TBD" : regularGameDate;

      return (
        <MenuItem value={id} key={id} selected={id === selectedGame}>
          {game.shortName} - {gameDate}
        </MenuItem>
      );
    });

    return (
      <Select
        value={selectedGame}
        autoWidth
        onChange={this.handleGameChange}
        name="selectedGame"
      >
        <MenuItem value=" " disabled>
          Select Game
        </MenuItem>
        {menuItems}
      </Select>
    );
  }

  cleanMobileNo(number) {
    let cleanNumber = number.replace(/\D/g, "");
    cleanNumber =
      cleanNumber.length === 11 ? cleanNumber.substr(1, 10) : cleanNumber;

    return `1${cleanNumber}`;
  }

  getSellers(seats) {
    console.log(seats);
    return _.chain(seats)
      .filter((seat) => seat.sellerMobileNo && seat.sellerMobileNo !== "-")
      .map(({ sellerMobileNo, sellerEmail, sellerFullName }) => ({
        email: sellerEmail.toLowerCase(),
        name: sellerFullName,
        mobileNo: sellerMobileNo && this.cleanMobileNo(sellerMobileNo),
      }))
      .uniqBy("email")
      .sortBy("email")
      .value();
  }

  getBuyers(orders) {
    return _.chain(orders)
      .filter((order) => order.mobileNo && order.mobileNo !== "-")
      .map(({ email, name, mobileNo, id }) => ({
        email,
        name,
        mobileNo: this.cleanMobileNo(mobileNo),
      }))
      .uniqBy("mobileNo")
      .sortBy("name")
      .value();
  }

  async games(slug) {
    return await API.get("v2", `marketplace/events/by/homeTeamSlug/${slug}`);
  }

  async onTeamSelection(slug) {
    this.setState({
      selectedTeam: slug,
      isLoading: true,
    });

    const games = await this.games(slug);
    const currentTime = Date.now();
    const filtered = games.filter((game) => {
      if (game.isTbd && !game.isArchived && !game.testGame) {
        return game;
      } else if (
        // get epoch timestamp of 2 hours ago and compare to game.date
        moment.tz(game.date, game.timezone).add(120, "minutes").valueOf() >=
        moment.tz(currentTime, game.timezone).valueOf() &&
        !game.isArchived &&
        !game.isTbd &&
        !game.testGame
      ) {
        return game;
      }

      return null;
    });

    const selectedGame = filtered[0].id;
    const orders = await this.getOrders(selectedGame);
    const seats = await this.getSeats(selectedGame);
    const sellers = await this.getSellers(seats);
    const buyers = await this.getBuyers(orders);
    this.setState({
      games: filtered,
      isLoading: false,
      selectedGame,
      orders,
      sellers,
      buyers,
      seats,
    });
  }

  getOrders(game) {
    return API.get("v2", `marketplace/orders/by/eventId?eventId=${game}`);
  }

  getSeats(game) {
    console.log(game, "game");
    return API.get(
      "v2",
      `marketplace/listings/by/eventId/${game}?include_seller_details=true`
    );
  }

  renderLogos() {
    const { selectedTeam } = this.state;
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Typography
          variant="body2"
          color="textPrimary"
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
          }}
        >
          Select Team
        </Typography>
        <Grid container spacing={32} className={classes.teamsList}>
          {TEAMS.map(team => (
            <Grid
              key={team.homeTeamSlug}
              item
              xs={6}
              md={2}
              className={classes.teamItemWrapper}
              onClick={() => this.onTeamSelection(team.homeTeamSlug)}
              style={
                selectedTeam && selectedTeam !== team.homeTeamSlug
                  ? { opacity: 0.5, transition: ".25s all ease-in-out" }
                  : { opacity: 1, transition: ".25s all ease-in-out" }
              }
            >
              <div className={classes.teamLogoWrapper}>
                <img
                  className={classes.teamLogo}
                  src={team.logo}
                  alt={team.fullName}
                />
              </div>
              <Typography
                variant="body2"
                style={{
                  cursor: "pointer",
                }}
              >
                {team.fullName}
              </Typography>
            </Grid>
          ))}         
        </Grid>
      </React.Fragment>
    );
  }

  renderForm() {
    const { classes } = this.props;
    const { selectedGame, msg, isSubmitting } = this.state;

    return (
      <Grid container>
        <Grid item xs={12} md={3} style={{ marginTop: "1rem" }}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.setState({ showDialog: true });
            }}
            disabled={!selectedGame}
          >
            <TextField
              id="standard-textarea"
              label={selectedGame ? "Message" : "Select game first"}
              placeholder={selectedGame ? "Message" : "Select game first"}
              multiline
              rows="4"
              className={classes.textField}
              margin="normal"
              disabled={!selectedGame || isSubmitting}
              fullWidth
              maxLength="140"
              value={msg}
              inputProps={{
                maxLength: 140,
              }}
              onChange={(e) => this.setState({ msg: e.target.value })}
            />
            <FormHelperText style={{ marginBottom: "1.5rem" }}>
              {140 - msg.length} character(s) remaining
            </FormHelperText>

            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={!msg || isSubmitting}
            >
              Send Message
            </Button>
          </form>
        </Grid>
      </Grid>
    );
  }

  handleClose = () => {
    this.setState({ showDialog: false });
  };

  async sendSms() {
    const { msg, recipients } = this.state;
    const { currUser } = this.props;
    // Need to alert developer when someone run bulk sms sending, will help developer to monitor the status
    const developerContacts = [
    ];

    this.setState({
      isSubmitting: true,
    });

    await API.post("v2", "communication/sms/send", {
      headers: { email: currUser.email },
      body: {
        msg,
        recipients: [...recipients, ...developerContacts],
      },
    })
      .then(() => {
        alert("Messages sent");

        this.setState({
          isSubmitting: false,
          showDialog: false,
          recipients: [],
          msg: "",
        });
      })
      .catch((e) => {
        // Temp forced to success since it's false error. the messages successfully sent but somehow it's fall into catch exception due to exceed timeout api
        // for propper message fix will be merged soon see here https://github.com/FansFirstTickets/fansfirst-v2/tree/sms-games
        alert("Messages sent");

        this.setState({
          isSubmitting: false,
          showDialog: false,
          recipients: [],
          msg: "",
        });
      });
  }

  renderDialog() {
    const { showDialog, msg, selectedGame, games, isSubmitting } = this.state;

    if (!games || !selectedGame) {
      return;
    }
    const game = games.filter((game) => game.id === selectedGame)[0];

    if (!game) {
      return;
    }

    return (
      <Dialog
        open={showDialog}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to send this message?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="body1">
              <strong>Game: </strong>
              {game.longName}{" "}
              {moment.tz(game.date, game.timezone).format("MM/DD/YYYY")}
            </Typography>
            <Typography variant="body1">
              <strong>Message: </strong>
              {msg}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleClose}
            color="primary"
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            onClick={this.sendSms}
            color="primary"
            autoFocus
            disabled={isSubmitting}
          >
            Send Now
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  handleToggle(buyer) {
    const { recipients } = this.state;
    const currentIndex = recipients
      .map((buyer) => buyer.mobileNo)
      .indexOf(buyer.mobileNo);
    let newSelected = [...recipients];

    if (currentIndex === -1) {
      newSelected.push(buyer);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    this.setState({
      recipients: newSelected,
    });
  }

  selectAll(isAllSelected, list) {
    if (!isAllSelected) {
      this.setState({
        isAllSelected: true,
        recipients: list,
      });
    } else {
      this.setState({
        isAllSelected: false,
        recipients: [],
      });
    }
  }

  loadBuyers() {
    const { classes } = this.props;
    const { buyers, recipients, isAllSelected } = this.state;

    return (
      <React.Fragment>
        <Typography variant="body2" style={{ marginTop: "1rem" }}>
          <Checkbox
            checked={isAllSelected}
            tabIndex={-1}
            disableRipple
            style={{ padding: 0 }}
            onClick={() => this.selectAll(isAllSelected, buyers)}
          />{" "}
          Select All
        </Typography>
        <List className={classes.list}>
          {buyers.map((buyer) => (
            <ListItem
              dense
              key={buyer.mobileNo}
              button
              onClick={() => this.handleToggle(buyer)}
            >
              <Checkbox
                checked={
                  recipients
                    .map((buyer) => buyer.mobileNo)
                    .indexOf(buyer.mobileNo) >= 0
                }
                tabIndex={-1}
                disableRipple
                inputProps={{ "aria-labelledby": buyer.mobileNo }}
                style={{ padding: 0 }}
              />
              <ListItemText
                id={buyer.mobileNo}
                primary={buyer.name}
                secondary={`${buyer.mobileNo} - ${buyer.email}`}
              />
            </ListItem>
          ))}
        </List>
      </React.Fragment>
    );
  }

  loadSellers() {
    const { classes } = this.props;
    const { sellers, recipients, isAllSelected } = this.state;

    return (
      <React.Fragment>
        <Typography variant="body2" style={{ marginTop: "1rem" }}>
          <Checkbox
            checked={isAllSelected}
            tabIndex={-1}
            disableRipple
            style={{ padding: 0 }}
            onClick={() => this.selectAll(isAllSelected, sellers)}
          />{" "}
          Select All
        </Typography>
        <List className={classes.list}>
          {sellers.map((seller) => (
            <ListItem
              dense
              key={seller.mobileNo}
              button
              onClick={() => this.handleToggle(seller)}
            >
              <Checkbox
                checked={
                  recipients
                    .map((seller) => seller.mobileNo)
                    .indexOf(seller.mobileNo) >= 0
                }
                tabIndex={-1}
                disableRipple
                inputProps={{ "aria-labelledby": seller.mobileNo }}
                style={{ padding: 0 }}
              />
              <ListItemText
                id={seller.mobileNo}
                primary={seller.email}
                secondary={`${seller.mobileNo} - ${seller.name}`}
              />
            </ListItem>
          ))}
        </List>
      </React.Fragment>
    );
  }

  render() {
    const { classes } = this.props;
    const { orders, buyers, sellers, isBuyerSelected, isSellerSelected } =
      this.state;

    return (
      <div className={classes.root} id="Seats">
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant="display2" style={{ marginBottom: "2rem" }}>
              Send SMS
            </Typography>
            {this.renderLogos()}
            {this.renderGames()}
            {this.renderForm()}
            {this.renderDialog()}
          </Grid>
          <Grid item xs={12} md={6}>
            <div style={{ marginBottom: "1rem" }}>
              <Button
                variant="contained"
                color={isBuyerSelected ? "secondary" : "default"}
                onClick={() =>
                  this.setState({
                    isBuyerSelected: true,
                    isSellerSelected: false,
                    recipients: [],
                  })
                }
                disabled={!orders}
                style={{ marginRight: "1rem" }}
              >
                Buyers
              </Button>
              <Button
                variant="contained"
                color={isSellerSelected ? "secondary" : "default"}
                onClick={() =>
                  this.setState({
                    isBuyerSelected: false,
                    isSellerSelected: true,
                    recipients: [],
                  })
                }
                disabled={!orders}
              >
                Sellers
              </Button>
            </div>
            {buyers && isBuyerSelected && this.loadBuyers()}
            {sellers && isSellerSelected && this.loadSellers()}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(SendGameSMS);
