import React, { Component } from "react";
import { Link } from "react-router-dom";
import { API } from "aws-amplify";
import _ from "lodash";
import querySearch from "stringquery";
import moment from "moment-timezone";
import { renderZoneName, renderRowName } from "../libs/shared/helpers";

import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";

const styles = {
  root: {
    flexGrow: 1,
  },
};

class Seats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      seats: null,
      sellerEmail: "",
      seatStatus: 2,
      isLoading: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleStatusFilter = this.handleStatusFilter.bind(this);
  }

  async componentWillMount() {
    const { email } = querySearch(this.props.location.search);
    this.setState({
      sellerEmail: email,
    });

    const { seats } = await this.seats(email);

    const sortedSeats = _.orderBy(
      seats,
      ["gameDate", "orderNo", "isArchived"],
      "asc"
    );

    this.setState({
      seats: sortedSeats,
      filteredSeats: sortedSeats.filter(
        (seat) => !seat.isSold && !seat.isArchived
      ),
    });
  }

  seats(email) {
    const sellerEmail = email ? email : this.state.sellerEmail;

    return API.get(
      "v2",
      `marketplace/listings/by/sellerEmail/${sellerEmail.toLowerCase()}/?includeSellerDetails=true&includeInactive=true`
    );
  }

  handleChange(e) {
    this.setState({
      sellerEmail: e.target.value,
    });
  }

  async onSubmit(e) {
    e.preventDefault();

    this.setState({ isLoading: true });
    
    const seats = await this.seats();
    
    this.setState({ isLoading: false });

    const sortedSeats = _.orderBy(
      seats,
      ["gameDate", "orderNo", "isArchived"],
      "asc"
    );

    this.setState({
      seats: sortedSeats,
      filteredSeats: sortedSeats.filter(
        (seat) =>
          !seat.isSold &&
          !seat.isArchived &&
          (seat.gameDate >=
            parseInt(moment().tz("America/Edmonton").valueOf()) ||
            seat.gameDetails.isTbd)
      ),
    });
  }

  async archiveSeat(id, game) {
    const { currUser } = this.props;
    try {
      await API.post(
        "v2",
        `marketplace/listings/archive?eventId=${game}&listingId=${id}&asAdmin=true`,
        { headers: { email: currUser.email } }
      );

      alert("Seat Archived!");

      const currSeats = this.state.seats.map((seat) => {
        if (seat.id === id) {
          seat.isArchived = true;
        }

        return seat;
      });

      this.setState(
        {
          seats: currSeats.filter((seat) => seat.seatId !== id),
        },
        () => {
          this.setState({
            filteredSeats: this.filterSeats(this.state.seatStatus),
          });
        }
      );
    } catch (e) {
      alert(e);
    }
  }

  async handlePriceEdit(e, seat) {
    const { currUser } = this.props;
    const newPrice = parseFloat(e.currentTarget.value);

    if (newPrice !== seat.price && newPrice !== 0) {
      await API.post(
        "v2",
        `marketplace/listings/update?adminName=${currUser.name}&eventId=${seat.game}&listingId=${seat.seatId}&price=${newPrice}`,
        { headers: { email: currUser.email } }
      );
    }
  }

  async handleQtyChange(e, seat) {
    const { currUser } = this.props;
    const noOfSeats = parseInt(e.currentTarget.value) + seat.seatsSold;

    if (noOfSeats !== seat.noOfSeats) {
      await API.post(
        "v2",
        `marketplace/listings/update?adminName=${currUser.name}&eventId=${seat.game}&listingId=${seat.seatId}&quantity=${noOfSeats}`,
        { headers: { email: currUser.email } }
      );
    }
  }

  renderSeats() {
    const { seatStatus, filteredSeats } = this.state;

    return (
      filteredSeats && (
        <React.Fragment>
          <FormControl>
            <InputLabel shrink htmlFor="seatsFilter">
              Filter Seats
            </InputLabel>

            <Select
              onChange={this.handleStatusFilter}
              value={seatStatus}
              label="Filter Seats"
              name="seatsFilter"
              id="seatsFilter"
            >
              <MenuItem value={1}>All Seats</MenuItem>
              <MenuItem value={2}>Active Seats</MenuItem>
              <MenuItem value={3}>Sold Out Seats</MenuItem>
              <MenuItem value={4}>Archived Seats</MenuItem>
            </Select>
          </FormControl>
          <Paper
            style={{
              marginTop: "2rem",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Game</TableCell>
                  <TableCell>Section</TableCell>
                  <TableCell>Row No</TableCell>
                  <TableCell>Seat Price</TableCell>
                  <TableCell>No. of Seats Available</TableCell>
                  <TableCell>Seats Sold</TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredSeats.map((seat, index) => {
                  return (
                    <TableRow key={seat.seatId}>
                      <TableCell>
                        {seat.gameDetails.isTbd
                          ? seat.gameName.split(" - ")[0] + " - TBD"
                          : seat.gameName}
                      </TableCell>
                      <TableCell>
                        {renderZoneName(seat.zone, seat.zoneNo, seat.row, seat.gameDetails.homeTeamSlug, seat.gameDetails.isSpecial)}
                      </TableCell>
                      <TableCell>{renderRowName(seat.zone, seat.row, seat.gameDetails.homeTeamSlug, seat.gameDetails.isSpecial)}</TableCell>
                      <TableCell>
                        {/* ${ seat.price } */}
                        <TextField
                          defaultValue={seat.price}
                          type="number"
                          min="1"
                          max="999"
                          inputProps={{
                            tabIndex: ++index,
                          }}
                          onBlur={(e) => this.handlePriceEdit(e, seat)}
                          disabled={seat.isSold || seat.isArchived}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          defaultValue={seat.noOfSeats - seat.seatsSold}
                          type="number"
                          min="1"
                          max="999"
                          inputProps={{
                            tabIndex: ++index,
                          }}
                          onBlur={(e) => this.handleQtyChange(e, seat)}
                          disabled={
                            seat.isSold ||
                            seat.isArchived ||
                            seat.noOfSeats - seat.seatsSold === 0
                          }
                        />
                      </TableCell>
                      <TableCell>{seat.seatsSold}</TableCell>
                      <TableCell>
                        <Button
                          href={`/seats/${seat.seatId}?game=${seat.game}`}
                        >
                          View Details
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          component={Link}
                          to={`/seats/${seat.seatId}/update?game=${
                            seat.game
                          }&return_url=${encodeURIComponent(
                            `/seats-by-seller?email=${this.state.sellerEmail}`
                          )}`}
                          disabled={seat.isSold || seat.isArchived}
                        >
                          {seat.isSold && "Sold Out"}
                          {seat.isArchived && "Archived"}
                          {!seat.isSold && !seat.isArchived && "Update Seat"}
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() =>
                            this.archiveSeat(seat.seatId, seat.game)
                          }
                          disabled={seat.isSold || seat.isArchived}
                        >
                          {seat.isSold && "Sold Out"}
                          {seat.isArchived && "Archived"}
                          {!seat.isSold && !seat.isArchived && "Archive"}
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        </React.Fragment>
      )
    );
  }

  renderForm() {
    return (
      <form onSubmit={this.onSubmit}>
        <TextField
          name="sellerEmail"
          value={this.state.sellerEmail}
          placeholder="Seller Email"
          onChange={this.handleChange}
        ></TextField>
        <Button type="submit" color="primary" variant="contained" disabled={this.state.isLoading}>
          {this.state.isLoading ? 'Searching' : 'Search'} 
        </Button>
      </form>
    );
  }

  filterSeats(status) {
    const { seats } = this.state;

    if (status === 1) {
      return seats;
    } else if (status === 2) {
      return seats.filter(
        (seat) =>
          !seat.isSold &&
          !seat.isArchived &&
          seat.gameDate >= parseInt(moment().tz("America/Edmonton").valueOf())
      );
    } else if (status === 3) {
      return seats.filter((seat) => seat.isSold && !seat.isArchived);
    } else if (status === 4) {
      return seats.filter(
        (seat) =>
          seat.isArchived ||
          parseInt(moment().tz("America/Edmonton").valueOf()) >= seat.gameDate
      );
    }
  }

  handleStatusFilter(e) {
    const status = e.target.value;

    this.setState({
      seatStatus: e.target.value,
      filteredSeats: this.filterSeats(status),
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root} id="Seats">
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="display2">Seats</Typography>
          </Grid>
          <Grid item xs={8}>
            {this.renderForm()}
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              marginTop: "2rem",
            }}
          >
            {this.renderSeats()}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Seats);
