import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CheckCircle, HighlightOff, InfoRounded, WarningRounded } from '@material-ui/icons';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  success: {
    color: green[800],
    marginRight: theme.spacing.unit,
  },
  error: {
    color: red[800],
    marginRight: theme.spacing.unit,
  },
  info: {
    color: blue[800],
    marginRight: theme.spacing.unit,
  },
  warning: {
    color: orange[800],
    marginRight: theme.spacing.unit,
  },
});

class DialogAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    }
  }

  destroy() {
    this.setState({ open: false });
    this.props.destroy()
  }

  renderIcon() {
    const { classes } = this.props;
    switch (this.props.type) {
      case 'success':
        return <CheckCircle className={classes.success} />
        break;
      case 'error':
        return <HighlightOff className={classes.error} />
        break;
      case 'info':
        return <InfoRounded className={classes.info} />
        break;
      case 'warning':
        return <WarningRounded className={classes.warning} />
        break;
      default:
        return null;
        break;
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.state.open}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <span className={classes.header}>{this.renderIcon()} {this.props.title}</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.props.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.destroy()} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

DialogAlert.defaultProps = {
  title: "Alert",
  description: "",
}

export default withStyles(styles)(DialogAlert);